import * as React from "react"
import { Container, Form, Row, Col, Button } from "react-bootstrap"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="Contact" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>
        Contact Us
        </h1>
       
      </Container>
    </div>
    <Container className="max-width-600 two-rem-padding">
      <p className="text-center py-4">How can I help?  Please contact me to learn more.</p>
    <Form 
                        name="contact"
                        method="post"
                        action="/contact-success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label for="firstName">First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name" id="firstName"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label for="lastName">Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"
                                       id="lastName"
                                     />
                            </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Form.Group>
                              <Form.Label for="email">Email address</Form.Label>
                              <Form.Control 
                                  required
                                  type="email" 
                                  name="email" 
                                  id="email"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                          <Form.Group>
                            <Form.Label for="phone">Phone</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                name="phone" 
                                id="phone"
                            />
                        </Form.Group>
                          </Col>
                        </Row>
                            
                        

                        
                        
                        <Form.Group>
                            <Form.Label for="help">How can we help you?</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="message"
                                rows="3"
                                id="help"
                             />
                        </Form.Group>
                        <Button type="submit" className="mt-1">Submit</Button>
                        </Form>
    </Container>
  </Layout>
)

export default IndexPage
